body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* this is the css file for the variables.css file */
:root {
  --color1: #201F1F;
  --color1-dark: rgb(33, 34, 37);
  --theme: #92b9cc;
  /* navbars */
  --theme-dark: #396fa3;
  --color3: rgb(116, 53, 53);
  --color3-dark: #29446e;
  --color4: #FF6F61;
  --color4-dark: #cc564b;
  --color5: #cc8800;
  --color5-dark: #af7500;
  --danger: #ff0000;
  --danger-dark: #960000;
  --warning: #ff9f00;
  --warning-dark: #b46f00;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color1-dark);
}


input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::selection {
  color: none;
  background: none;
}

/* For Mozilla Firefox */
*::-moz-selection {
  color: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white
}