.App {
  text-align: center;
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  background-color: var(--color1);
  background-image: url("../../assets/textures/dark-brick-wall.png");
}

@media screen and (max-width: 768px) {
  .App {
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
  }

  html, body {
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1200px) {
  .App {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    overflow: hidden;
  }
}



.img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-color: var(--color1);
  z-index: 1;
  position: fixed;
  right: 0;
}


a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  height: auto;
  display: block;
}


h1 {
  color: var(--color1);
  font-size: 3em;
  font-weight: bold;
  padding: 0;
  margin: 0 0 0 0;
}